import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function PlusToggleIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C7.724 16 7.5 15.776 7.5 15.5V8.5H0.5C0.224 8.5 0 8.276 0 8C0 7.724 0.224 7.5 0.5 7.5H7.5V0.5C7.5 0.224 7.724 0 8 0C8.276 0 8.5 0.224 8.5 0.5V7.5H15.5C15.776 7.5 16 7.724 16 8C16 8.276 15.776 8.5 15.5 8.5H8.5V15.5C8.5 15.776 8.276 16 8 16Z"
        fill="black"
      />
    </styled.Svg>
  );
}

export default styled(PlusToggleIcon);
