import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function MinusToggleIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      css={compose()}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.25 12.0381C0.25 11.7619 0.473858 11.5381 0.75 11.5381H23.25C23.5261 11.5381 23.75 11.7619 23.75 12.0381C23.75 12.3142 23.5261 12.5381 23.25 12.5381H0.75C0.473858 12.5381 0.25 12.3142 0.25 12.0381Z"
      />
    </styled.Svg>
  );
}

export default styled(MinusToggleIcon);
